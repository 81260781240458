import api from './api';

export const scheduleApi = {
  getMentorAvailability: async (mentorId) => {
    return await api.get(`/api/mentors/${mentorId}/availability`);
  },

  getAvailableSlots: async (mentorId, date) => {
    console.log('Chamando slots para:', mentorId, date);
    return await api.get(`/api/mentors/${mentorId}/slots/${date}`);
  },

  bookSession: async (mentorId, sessionData) => {
    return await api.post(`/api/mentorship-sessions`, {
      mentor_id: mentorId,
      ...sessionData
    });
  }
}; 