import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authApi, mentorApi } from '../services/api';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ExploreContent from '../components/ExploreContent';
import './Explore.css';

function Explore() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const [userResponse, mentorsResponse] = await Promise.all([
          authApi.getCurrentUser(),
          mentorApi.getAllMentors()
        ]);
        setUser(userResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        if (error.response?.status === 401) {
          navigate('/login');
        }
        setLoading(false);
      }
    }
    fetchData();
  }, [navigate]);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error}</p>;
  if (!user) return null;

  return (
    <div className="explore-page">
      <Header user={user} setUser={setUser} />
      <div className="explore-content-wrapper">
        <Sidebar />
        <ExploreContent />
      </div>
    </div>
  );
}

export default Explore;