import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  withCredentials: true
});

// Interceptor para tratamento de erros
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      // Redirecionar para login se não estiver autenticado
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const authApi = {
  getCurrentUser: () => api.get('/auth/me'),
  logout: () => api.post('/auth/logout'),
  loginWithGoogle: () => api.get('/auth/google/login'),
  getCalendarAuth: () => api.get('/auth/google/calendar')
};

export const userApi = {
  getCurrentUser: () => api.get('/api/users/me'),
  updateProfile: async (data) => {
    try {
      const response = await api.put('/api/users/profile', data, {
        withCredentials: true
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProfileByHandle: (handle) => api.get(`/api/profile/${handle}`)
};

export const mentorApi = {
  getAllMentors: () => api.get('/api/mentors'),
  getMentorById: (id) => api.get(`/api/mentors/${id}`),
  getMentorSlots: (mentorId, date) => api.get(`/api/mentors/${mentorId}/slots/${date}`),
  updateAvailability: (data) => api.put('/api/mentors/availability', data),
  addSlots: (data) => api.post('/api/mentors/slots', data),
  deleteSlot: (slotId) => api.delete(`/api/mentors/slots/${slotId}`)
};

export const sessionApi = {
  getMentorSessions: () => api.get('/api/sessions/mentor'),
  confirmSession: (sessionId) => api.put(`/api/sessions/${sessionId}/confirm`),
  cancelSession: (sessionId) => api.put(`/api/sessions/${sessionId}/cancel`),
  createSession: (data) => api.post('/api/sessions', data)
};

export const adminApi = {
  getUsers: () => api.get('/api/admin/users'),
  approveUser: (userId, status) => api.post(`/api/admin/approve-user/${userId}`, { status })
};

export const availabilityApi = {
  getMentorAvailability: (mentorId) => api.get(`/api/mentors/${mentorId}/availability`),
  updateAvailability: (mentorId, data) => api.put(`/api/mentors/availability`, { availabilities: data })
};

export default api; 