import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import CompleteProfile from './pages/CompleteProfile';
import Pending from './pages/Pending';
import Dashboard from './pages/Dashboard';
import Explore from './pages/Explore';
import Availability from './pages/Availability';
import UserProfile from './pages/UserProfile';
import Agendamentos from './pages/Agendamentos';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AppContent() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/me`, {
        withCredentials: true
      });
      console.log('Resposta do current_user:', response.data);
      if (response.data && response.data.id) {
        setUser(response.data);
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Erro ao buscar usuário:', error);
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    console.log('Estado do usuário atualizado:', user);
    console.log('Estado de autenticação:', isAuthenticated);
  }, [user, isAuthenticated]);

  const handleLoginSuccess = (response) => {
    // Supondo que a resposta do backend inclua token e dados do usuário
    const { token, user } = response.data;
    
    // Salvar o token
    localStorage.setItem('token', token);
    
    // Atualizar o estado do usuário
    setUser(user);
    setIsAuthenticated(true);
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className={`App ${isLoginPage ? 'login-page' : ''}`}>
      <Header user={user} setUser={setUser} setIsAuthenticated={setIsAuthenticated} />
      <div className={`content ${isLoginPage ? 'login-content' : ''}`}>
        {isAuthenticated && !isLoginPage && <Sidebar />}
        <main className={`main-content ${isLoginPage ? 'login-main-content' : ''}`}>
          <Routes>
            <Route path="/login" element={
              <Login setUser={setUser} setIsAuthenticated={setIsAuthenticated} />
            } />
            <Route path="/complete-profile" element={
              isAuthenticated && user && !user.profile_completed ? <CompleteProfile /> : <Navigate to="/dashboard" />
            } />
            <Route path="/pending" element={
              isAuthenticated && user && user.approval_status === 'pending' ? <Pending /> : <Navigate to="/dashboard" />
            } />
            <Route path="/dashboard" element={
              isAuthenticated ? <Dashboard /> : <Navigate to="/login" />
            } />
            <Route path="/" element={
              isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
            } />
            <Route path="/explore" element={isAuthenticated ? <Explore /> : <Navigate to="/login" />} />
            <Route path="/disponibilidade" element={isAuthenticated ? <Availability /> : <Navigate to="/login" />} />
            <Route path="/profile/:id" element={<Navigate to={`/${user?.handle}`} />} />
            <Route path="/:handle" element={
              isAuthenticated ? <UserProfile /> : <Navigate to="/login" />
            } />
            <Route path="/agendamentos" element={<Agendamentos />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
