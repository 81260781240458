import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import SearchBar from './SearchBar'; // Importe o componente SearchBar
import './Header.css';
import { authApi } from '../services/api';

function Header({ user, setUser, setIsAuthenticated }) {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleLogout = async () => {
    try {
      await authApi.logout();
      setUser(null);
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const handleProfileClick = () => {
    if (user && user.id) {
      navigate(`/profile/${user.id}`);
    } else {
      console.error('ID do usuário não disponível');
    }
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-content">
        <div className="header-logo">
          <img src="/Logo.svg" alt="Eleva Talk Logo" />
        </div>
        {user && (
          <div className="header-search">
            <SearchBar />
          </div>
        )}
        {user && (
          <div className="header-user" ref={dropdownRef}>
            <img 
              src={user.picture || 'https://via.placeholder.com/40'}
              alt={user.name} 
              className="user-avatar" 
              onError={(e) => { e.target.onerror = null; e.target.src = 'https://via.placeholder.com/40' }}
            />
            <span className="user-name">{user.name}</span>
            <button onClick={toggleDropdown} className="dropdown-toggle">
              <ChevronDownIcon className="chevron-icon" />
            </button>
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <button onClick={handleProfileClick}>Ir para meu perfil</button>
                <button onClick={handleLogout}>Sair</button>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;