import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StarIcon as StarIconSolid } from '@heroicons/react/20/solid';
import { VideoCameraIcon, ClockIcon } from '@heroicons/react/24/outline';
import './UserProfile.css';
import Tabs from '../components/Tabs';
import MentorSchedule from '../components/MentorSchedule';
import Modal from '../components/Modal/Modal';
import Button from '../components/Button/Button';
import SpecialtySelect from '../components/SpecialtySelect/SpecialtySelect';
import { userApi } from '../services/api';
import axios from 'axios';

// Array de estados brasileiros
const ESTADOS_BR = [
  'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO',
  'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI',
  'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
];

function UserProfile() {
  const { handle } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('Sobre');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [editFormData, setEditFormData] = useState({
    name: '',
    company: '',
    about_me: '',
    career_start_year: '',
    registration_number: '',
    registration_state: '',
    profession_id: '',
    specialties: []
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await userApi.getProfileByHandle(handle);
        console.log('Dados do usuário recebidos:', response.data);
        console.log('Average Rating:', response.data.average_rating);
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar usuário:', error);
        setError('Falha ao carregar dados do usuário');
        setLoading(false);
      }
    };

    fetchUser();
  }, [handle]);

  useEffect(() => {
    if (user) {
      console.log('Especialidades do usuário:', user.specialties);
      setEditFormData({
        name: user.name || '',
        company: user.company || '',
        about_me: user.about_me || '',
        career_start_year: user.career_start_year || '',
        registration_number: user.registration_number || '',
        registration_state: user.registration_state || '',
        profession_id: user.profession_id || '',
        specialties: Array.isArray(user.specialties) 
          ? user.specialties.map(specialty => {
              const id = specialty.specialty_id || specialty.id;
              if (!id) {
                console.warn('Especialidade sem ID:', specialty);
                return null;
              }
              return {
                id: id,
                name: specialty.name
              };
            }).filter(Boolean)
          : []
      });
    }
  }, [user]);

  // Adicionar useEffect para carregar especialidades
  useEffect(() => {
    const loadSpecialties = async () => {
      if (isEditModalOpen && user?.profession_id) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/professions/${user.profession_id}/specialties`
          );
          setSpecialtyOptions(response.data);
        } catch (error) {
          console.error('Erro ao carregar especialidades:', error);
        }
      }
    };

    loadSpecialties();
  }, [isEditModalOpen, user?.profession_id]);

  const handleEditSubmit = async () => {
    try {
      await userApi.updateProfile(editFormData);
      const response = await userApi.getProfileByHandle(handle);
      setUser(response.data);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!user) {
    return <div>Carregando...</div>;
  }

  const formatRating = (rating) => {
    if (rating === undefined || rating === null) {
      return 'N/A';
    }
    return typeof rating === 'number' ? rating.toFixed(1) : rating.toString();
  };

  const tabs = [
    { id: 'Sobre', label: 'Sobre' },
    { id: 'Avaliações', label: 'Avaliações' },
  ];

  // Função para calcular anos de carreira
  const calculateCareerYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - startYear;
  };

  // Gerar anos para o seletor
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1970; year--) {
      const careerYears = calculateCareerYears(year);
      years.push({
        value: year,
        label: `${year} (${careerYears} ${careerYears === 1 ? 'ano' : 'anos'} de carreira)`
      });
    }
    return years;
  };

  return (
    <div className="user-profile">
      <div className="cover-image"></div>
      <div className="profile-header">
        <div className="profile-image-container">
          <img src={user.picture} alt={user.name} className="profile-image" />
        </div>
        <div className="profile-info">
          <h1>{user.name}</h1>
          <p className="user-handle">@{user.handle}</p>
          <p>
            <span className="info-value">{user.profession_name}</span>
            <span className="info-label"> na empresa </span>
            <span className="info-value">{user.company}</span>
          </p>
          {/* Forçar a exibição do rating para debug */}
          <div className="mentor-rating">
            <StarIconSolid className="star-icon" />
            <span>{formatRating(user.average_rating)} (Debug: {JSON.stringify(user.average_rating)})</span>
          </div>
          <button 
            className="edit-profile-btn" 
            onClick={() => setIsEditModalOpen(true)}
          >
            Editar perfil
          </button>
        </div>
      </div>
      
      <div className="profile-content-container">
        <div className="profile-main">
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className="profile-content">
            {activeTab === 'Sobre' && (
              <div className="profile-content">
                <div className="info-section">
                  <h3>Sobre mim</h3>
                  <p>{user.about_me || 'Esta pessoa ainda não adicionou uma biografia'}</p>
                </div>
                
                <div className="info-section">
                  <h3>Especialidades</h3>
                  <div className="specialty-tags">
                    {Array.isArray(user.specialties) && user.specialties.length > 0 ? (
                      user.specialties.map((specialty, index) => (
                        <span key={index} className="specialty-tag">{specialty}</span>
                      ))
                    ) : (
                      <span className="specialty-tag empty-tag">
                        Especialidades não informadas
                      </span>
                    )}
                  </div>
                </div>
                
                <div className="info-section">
                  <h3>Tempo de carreira</h3>
                  <p className="career-info">
                    <ClockIcon className="info-icon" />
                    {user.career_years ? (
                      `${user.career_years} ${user.career_years === 1 ? 'ano' : 'anos'}`
                    ) : (
                      'Tempo de carreira não informado'
                    )}
                  </p>
                </div>
                
                <div className="info-section">
                  <h3>Mentorias realizadas</h3>
                  <p className="mentorship-info">
                    <VideoCameraIcon className="info-icon" />
                    {user.total_mentorships > 0 
                      ? `${user.total_mentorships} ${user.total_mentorships === 1 ? 'mentoria realizada' : 'mentorias realizadas'}`
                      : 'Nenhuma mentoria realizada ainda'
                    }
                  </p>
                </div>
              </div>
            )}
            {activeTab === 'Avaliações' && (
              <div className="reviews">
                <h3>Avaliações</h3>
                <p className="no-reviews-message">
                  Ainda não há avaliações para este perfil. As avaliações dos mentorados serão exibidas aqui quando disponíveis.
                </p>
              </div>
            )}
          </div>
        </div>
        
        {user && user.is_mentor && (
          <div className="profile-schedule">
            <MentorSchedule mentorId={user.id} />
          </div>
        )}
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Editar Perfil"
        size="medium"
        footer={
          <>
            <Button 
              variant="secondary" 
              onClick={() => setIsEditModalOpen(false)}
            >
              Cancelar
            </Button>
            <Button 
              variant="primary" 
              onClick={handleEditSubmit}
            >
              Salvar
            </Button>
          </>
        }
      >
        <div className="edit-profile-form">
          <div className="form-group">
            <label>Nome</label>
            <input
              type="text"
              value={editFormData.name}
              onChange={(e) => setEditFormData(prev => ({
                ...prev,
                name: e.target.value
              }))}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label>Empresa</label>
            <input
              type="text"
              value={editFormData.company}
              onChange={(e) => setEditFormData(prev => ({
                ...prev,
                company: e.target.value
              }))}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label>
              {user.profession_name === 'Médico' ? 'CRM' : 'COREN'}
            </label>
            <div className="registration-group">
              <input
                type="text"
                value={editFormData.registration_number}
                onChange={(e) => setEditFormData(prev => ({
                  ...prev,
                  registration_number: e.target.value
                }))}
                className="form-input"
              />
              <select
                value={editFormData.registration_state}
                onChange={(e) => setEditFormData(prev => ({
                  ...prev,
                  registration_state: e.target.value
                }))}
                className="form-select"
              >
                <option value="">UF</option>
                {ESTADOS_BR.map(estado => (
                  <option key={estado} value={estado}>
                    {estado}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label>Quando sua carreira como {user.profession_name} começou?</label>
            <select
              value={editFormData.career_start_year}
              onChange={(e) => setEditFormData(prev => ({
                ...prev,
                career_start_year: e.target.value
              }))}
              className="form-select"
            >
              <option value="">Selecione o ano</option>
              {generateYearOptions().map(year => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Especialidades</label>
            <SpecialtySelect
              specialties={specialtyOptions}
              selectedSpecialties={editFormData.specialties}
              onChange={(specialty) => setEditFormData(prev => ({
                ...prev,
                specialties: [...prev.specialties, specialty]
              }))}
              onRemove={(specialtyId) => setEditFormData(prev => ({
                ...prev,
                specialties: prev.specialties.filter(s => s.id !== specialtyId)
              }))}
              professionId={user.profession_id}
            />
          </div>

          <div className="form-group">
            <label>Sobre mim</label>
            <textarea
              value={editFormData.about_me}
              onChange={(e) => setEditFormData(prev => ({
                ...prev,
                about_me: e.target.value
              }))}
              className="form-textarea"
              rows={4}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UserProfile;