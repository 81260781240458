import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Filters from './Filters';
import SearchBar from './SearchBar';
import MentorCard from './MentorCard';
import './ExploreContent.css';

function ExploreContent() {
  const [activeFilter, setActiveFilter] = useState('Todos(as)');
  const [mentors, setMentors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchMentors();
  }, []);

  const fetchMentors = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/mentors`, { withCredentials: true });
      setMentors(Array.isArray(response.data) ? response.data : []);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar mentores:', error);
      setError('Falha ao carregar mentores. Por favor, tente novamente mais tarde.');
      setLoading(false);
    }
  };

  const filters = ['Todos(as)', 'Médicos(as)', 'Enfermeiras(os)'];

  const filteredMentors = mentors.filter(mentor => {
    const matchesSearch = mentor.name.toLowerCase().includes(searchTerm.toLowerCase());
    let matchesFilter = false;
    
    switch(activeFilter) {
      case 'Todos(as)':
        matchesFilter = true;
        break;
      case 'Médicos(as)':
        matchesFilter = mentor.profession_name === 'Médico' || mentor.profession_name === 'Médica';
        break;
      case 'Enfermeiras(os)':
        matchesFilter = mentor.profession_name === 'Enfermeiro' || mentor.profession_name === 'Enfermeira';
        break;
      default:
        matchesFilter = true;
    }
    
    return matchesSearch && matchesFilter;
  });

  return (
    <div className="explore-content">
      <div className="explore-header">
        <Filters filters={filters} activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
        <div className="explore-search-wrapper">
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
      </div>
      <div className="mentors-grid">
        {loading ? (
          <div className="loading">Carregando...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : filteredMentors.length > 0 ? (
          filteredMentors.map(mentor => <MentorCard key={mentor.id} mentor={mentor} />)
        ) : (
          <div className="no-results">Nenhum mentor encontrado.</div>
        )}
      </div>
    </div>
  );
}

export default ExploreContent;