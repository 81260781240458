import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { format, startOfWeek, addDays, parseISO, startOfMonth, endOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { scheduleApi } from '../services/scheduleApi';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import './MentorSchedule.css';

function MentorSchedule({ mentorId }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isDateBooked, setIsDateBooked] = useState(false);

  const dayMapping = {
    'domingo': 0,
    'segunda': 1,
    'terca': 2,
    'quarta': 3,
    'quinta': 4,
    'sexta': 5,
    'sabado': 6
  };

  useEffect(() => {
    loadInitialAvailability();
  }, [mentorId]);

  const loadInitialAvailability = async () => {
    try {
      setIsLoading(true);
      const response = await scheduleApi.getMentorAvailability(mentorId);
      
      const dayMapping = {
        'domingo': 0,
        'segunda': 1,
        'terca': 2,
        'quarta': 3,
        'quinta': 4,
        'sexta': 5,
        'sabado': 6
      };

      // Pegar apenas os dias habilitados
      const enabledDays = Object.entries(response.data)
        .filter(([_, data]) => data.enabled && data.slots.length > 0);

      // Array para armazenar as próximas ocorrências
      const nextDates = [];
      const today = new Date();
      
      enabledDays.forEach(([day, data]) => {
        const dayNumber = dayMapping[day];
        let currentDate = new Date(today);
        
        // Encontrar a próxima ocorrência deste dia da semana
        while (currentDate.getDay() !== dayNumber) {
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // Adicionar 4 ocorrências deste dia
        for (let i = 0; i < 4; i++) {
          nextDates.push({
            day: day.substring(0, 3).toUpperCase(),
            date: format(currentDate, 'd'),
            fullDate: new Date(currentDate),
            slots: data.slots.length
          });
          
          currentDate.setDate(currentDate.getDate() + 7);
        }
      });

      // Ordenar por data
      const sortedDates = nextDates.sort((a, b) => a.fullDate - b.fullDate);
      
      // Pegar os 4 primeiros dias
      const days = sortedDates.slice(0, 4);

      setAvailableDays(days);
      setAvailableDates(nextDates.map(day => day.fullDate));

      // Selecionar automaticamente o primeiro dia disponível
      if (days.length > 0) {
        const nextAvailableDate = days[0].fullDate;
        setSelectedDate(nextAvailableDate);
        // Carregar os slots para este dia
        await loadTimeSlots(nextAvailableDate);
      }

    } catch (error) {
      toast.error('Erro ao carregar disponibilidade');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBookSession = async () => {
    if (!selectedDate || !selectedTime) return;

    try {
      setIsBooking(true);
      await scheduleApi.bookSession(mentorId, {
        date: format(selectedDate, 'yyyy-MM-dd'),
        time: selectedTime
      });

      toast.success('Sessão agendada com sucesso!');
      setSelectedDate(null);
      setSelectedTime(null);
      loadInitialAvailability();
    } catch (error) {
      toast.error('Erro ao agendar sessão');
    } finally {
      setIsBooking(false);
    }
  };

  // Atualizar loadTimeSlots para retornar os slots
  const loadTimeSlots = async (date) => {
    try {
      setIsLoading(true);
      const formattedDate = format(date, 'yyyy-MM-dd');
      const response = await scheduleApi.getAvailableSlots(mentorId, formattedDate);
      
      const { slots, allSlotsBooked } = response.data;
      
      const availableSlots = slots
        .filter(slot => !slot.is_booked)
        .map(slot => ({
          start: slot.start,
          end: slot.end,
          id: slot.id
        }));
      
      setTimeSlots(availableSlots);
      setIsDateBooked(allSlotsBooked);
      
      if (availableSlots.length > 0 && !selectedTime) {
        setSelectedTime(availableSlots[0].start);
      }
    } catch (error) {
      console.error('Erro detalhado:', error);
      toast.error('Erro ao carregar horários');
    } finally {
      setIsLoading(false);
    }
  };

  const getNextAvailableDays = (selectedDate, enabledDays) => {
    const nextDates = [];
    let currentDate = new Date(selectedDate);
    
    // Primeiro, adiciona o dia selecionado se for um dia habilitado
    const selectedDayOfWeek = currentDate.getDay();
    const selectedDayEnabled = enabledDays.find(
      ([day]) => dayMapping[day] === selectedDayOfWeek
    );
    
    if (selectedDayEnabled) {
      nextDates.push({
        day: selectedDayEnabled[0].substring(0, 3).toUpperCase(),
        date: format(currentDate, 'd'),
        fullDate: new Date(currentDate),
        slots: selectedDayEnabled[1].slots.length
      });
    }
    
    // Depois, encontra os próximos dias disponíveis
    while (nextDates.length < 4) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getDay();
      
      const enabledDay = enabledDays.find(
        ([day]) => dayMapping[day] === dayOfWeek
      );
      
      if (enabledDay) {
        nextDates.push({
          day: enabledDay[0].substring(0, 3).toUpperCase(),
          date: format(currentDate, 'd'),
          fullDate: new Date(currentDate),
          slots: enabledDay[1].slots.length
        });
      }
    }
    
    return nextDates;
  };

  const handleDateSelect = async (date) => {
    try {
      setIsLoading(true);
      
      // Se estiver vindo do calendário, reorganiza os dias
      if (showCalendar) {
        const response = await scheduleApi.getMentorAvailability(mentorId);
        const enabledDays = Object.entries(response.data)
          .filter(([_, data]) => data.enabled && data.slots.length > 0);
        const nextDays = getNextAvailableDays(date, enabledDays);
        setAvailableDays(nextDays);
      } else {
        // Se já estiver na visão de dias, apenas seleciona o dia
        const selectedDay = availableDays.find(
          day => format(day.fullDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
        );
        if (selectedDay) {
          setSelectedDate(selectedDay.fullDate);
        }
      }

      setSelectedDate(date);
      setSelectedTime(null);
      setShowCalendar(false);
      await loadTimeSlots(date);
    } catch (error) {
      console.error('Erro ao selecionar data:', error);
      toast.error('Erro ao carregar disponibilidade');
    } finally {
      setIsLoading(false);
    }
  };

  const isDateAvailable = (date) => {
    return availableDates.some(availableDate => 
      format(availableDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
    );
  };

  const loadMonthAvailability = async (date) => {
    try {
      setIsLoading(true);
      const response = await scheduleApi.getMentorAvailability(mentorId);
      
      const dayMapping = {
        'domingo': 0,
        'segunda': 1,
        'terca': 2,
        'quarta': 3,
        'quinta': 4,
        'sexta': 5,
        'sabado': 6
      };

      const enabledDays = Object.entries(response.data)
        .filter(([_, data]) => data.enabled && data.slots.length > 0);

      const monthStart = startOfMonth(date);
      const monthEnd = endOfMonth(date);
      const monthDates = [];

      // Criar uma nova data para iteração
      let currentDate = new Date(monthStart);

      while (currentDate <= monthEnd) {
        const dayOfWeek = currentDate.getDay();
        
        // Encontrar se este dia da semana está habilitado
        const enabledDay = enabledDays.find(
          ([day]) => dayMapping[day] === dayOfWeek
        );
        
        if (enabledDay) {
          monthDates.push({
            day: enabledDay[0].substring(0, 3).toUpperCase(),
            date: format(currentDate, 'd'),
            fullDate: new Date(currentDate),
            slots: enabledDay[1].slots.length
          });
        }
        
        // Avançar para o próximo dia
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Atualizar as datas disponíveis
      setAvailableDates(prev => {
        const newDates = monthDates.map(d => d.fullDate);
        return [...new Set([...prev, ...newDates])];
      });
      
    } catch (error) {
      console.error('Erro ao carregar disponibilidade:', error);
      toast.error('Erro ao carregar disponibilidade');
    } finally {
      setIsLoading(false);
    }
  };

  // Adicionar o handler de mudança de mês no DatePicker
  const handleMonthChange = (date) => {
    loadMonthAvailability(date);
  };

  return (
    <div className="schedule-container">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner" />
        </div>
      )}
      
      {!showCalendar ? (
        <>
          <h2 className="schedule-title">Sessões disponíveis</h2>
          <p className="schedule-subtitle">Saiba os próximos dias em que este(a) mentor(a) está disponível.</p>

          <div className="dates-container">
            {availableDays.map((day) => (
              <button
                key={day.fullDate.toString()}
                className={`date-card ${
                  selectedDate && format(selectedDate, 'yyyy-MM-dd') === format(day.fullDate, 'yyyy-MM-dd') 
                    ? 'selected' 
                    : ''
                } ${day.allSlotsBooked ? 'booked' : ''}`}
                onClick={() => handleDateSelect(day.fullDate)}
                disabled={isLoading}
              >
                <span className="date-weekday">{day.day}</span>
                <span className="date-full">{day.date}</span>
                <span className={`date-slots ${day.allSlotsBooked ? 'date-slots-esgotado' : ''}`}>
                  {day.allSlotsBooked 
                    ? 'Esgotado' 
                    : day.slots === 1 
                      ? '1 vaga'
                      : `${day.slots} vagas`
                  }
                </span>
              </button>
            ))}
            <button 
              className="view-all-link"
              onClick={() => setShowCalendar(true)}
              disabled={isLoading}
            >
              Ver mais
              <ChevronRightIcon className="view-icon" />
            </button>
          </div>

          {selectedDate && (
            <>
              <h3 className="timeslots-title">Horários disponíveis</h3>
              <div className="timeslots-grid">
                {timeSlots.map((slot) => (
                  <button
                    key={slot.id}
                    className={`time-slot ${selectedTime === slot.start ? 'selected' : ''}`}
                    onClick={() => setSelectedTime(slot.start)}
                    disabled={isLoading || isBooking}
                  >
                    {slot.start}
                  </button>
                ))}
              </div>
            </>
          )}

          {selectedDate && selectedTime && (
            <button 
              className="book-button"
              onClick={handleBookSession}
              disabled={isLoading || isBooking}
            >
              {isBooking ? 'Agendando...' : 'Agendar sessão'}
            </button>
          )}
        </>
      ) : (
        <div className="calendar-container">
          <DatePicker
            inline
            selected={selectedDate}
            onChange={handleDateSelect}
            onMonthChange={handleMonthChange}
            locale={ptBR}
            dateFormat="dd/MM/yyyy"
            highlightDates={availableDates}
            dayClassName={date =>
              isDateAvailable(date) ? 'available-day' : 'unavailable-day'
            }
            showMonthYearPicker={false}
            minDate={new Date()} // Previne seleção de datas passadas
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className="calendar-header">
                <button 
                  onClick={() => {
                    decreaseMonth();
                    handleMonthChange(addDays(date, -1));
                  }} 
                  disabled={prevMonthButtonDisabled || isLoading}
                >
                  <ChevronLeftIcon className="calendar-nav-icon" />
                </button>
                <span className="calendar-month">
                  {format(date, 'MMMM yyyy', { locale: ptBR })}
                </span>
                <button 
                  onClick={() => {
                    increaseMonth();
                    handleMonthChange(addDays(date, 31));
                  }} 
                  disabled={nextMonthButtonDisabled || isLoading}
                >
                  <ChevronRightIcon className="calendar-nav-icon" />
                </button>
              </div>
            )}
          />
          <button 
            className="back-to-week"
            onClick={() => setShowCalendar(false)}
          >
            Voltar para visão semanal
          </button>
        </div>
      )}
    </div>
  );
}

export default MentorSchedule;