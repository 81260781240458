import React from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';
import './InviteCard.css';

function InviteCard({ session, onConfirm, onDecline }) {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return format(new Date(dateString), "dd/MM/yyyy", { locale: ptBR });
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  console.log('Session data:', session);

  return (
    <div className="invite-card">
      <div className="invite-content">
        <div className="mentor-avatar">
          {session.mentee_picture ? (
            <img 
              src={session.mentee_picture} 
              alt={session.mentee_name} 
              className="avatar"
            />
          ) : (
            <div className="avatar-placeholder">
              {session.mentee_name ? session.mentee_name.charAt(0).toUpperCase() : '?'}
            </div>
          )}
        </div>
        
        <div className="invite-details">
          <h3>Mentoria com {session.mentee_name || 'Mentorado'}</h3>
          
          <div className="session-time">
            <CalendarIcon className="time-icon" /> {formatDate(session.date)}
            <ClockIcon className="time-icon ml-4" /> {formatTime(session.time)}
          </div>

          <div className="description">
            <strong>Descrição</strong>
            <p>{session.description || 'Sem descrição'}</p>
          </div>
        </div>
      </div>

      {session.status === 'pendente' ? (
        <div className="invite-actions">
          <p className="confirmation-text">Deseja confirmar esta mentoria?</p>
          <div className="action-buttons">
            <button 
              className="confirm-button"
              onClick={() => onConfirm(session.id)}
            >
              Sim
            </button>
            <button 
              className="decline-button"
              onClick={() => onDecline(session.id)}
            >
              Não
            </button>
          </div>
        </div>
      ) : session.status === 'confirmada' && session.meet_link && (
        <div className="invite-actions">
          <a 
            href={session.meet_link} 
            target="_blank" 
            rel="noopener noreferrer"
            className="meet-button"
          >
            Entrar no Google Meet
          </a>
        </div>
      )}
    </div>
  );
}

export default InviteCard; 