import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../services/api';
import Sidebar from '../components/Sidebar';
import HomeContent from '../components/HomeContent';
import Header from '../components/Header';
import './Dashboard.css';

function Dashboard() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      try {
        setLoading(true);
        const response = await authApi.getCurrentUser();
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar usuário:', error);
        if (error.response?.status === 401) {
          navigate('/login');
        } else {
          setError(error.message);
        }
        setLoading(false);
      }
    }
    fetchUser();
  }, [navigate]);

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error}</p>;
  if (!user) return null;

  // Calcular o progresso
  const calculateProgress = () => {
    let progress = 1; // Começa com 1 porque o perfil já está completo
    if (user.has_availability) progress++;
    // Adicione mais verificações aqui se necessário
    return (progress / 2) * 100; // Assume 2 etapas no total por enquanto
  };

  return (
    <div className="dashboard-container">
      <Header user={user} setUser={setUser} />
      <div className="dashboard-content">
        <Sidebar />
        <HomeContent 
          user={user} 
          progressPercentage={calculateProgress()}
          availabilityConfigured={user.has_availability}
        />
      </div>
    </div>
  );
}

export default Dashboard;