import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import './HomeContent.css';

function HomeContent({ user }) {
  console.log('HomeContent recebeu user:', user);

  const hasConfiguredAvailability = () => {
    const result = user?.has_availability === true;
    console.log('hasConfiguredAvailability:', {
      user: user,
      has_availability: user?.has_availability,
      result: result
    });
    return result;
  };

  const steps = [
    { id: 'basic_info', label: 'Preencha suas informações básicas', completed: true },
    { id: 'profile_photo', label: 'Adicione sua foto de perfil', completed: Boolean(user?.picture) },
    { id: 'calendar', label: 'Conecte seu calendário', completed: Boolean(user?.calendar_connected) },
    { id: 'availability', label: 'Configure sua disponibilidade', completed: hasConfiguredAvailability() }
  ];

  const completedSteps = steps.filter(step => step.completed).length;
  const progress = (completedSteps / steps.length) * 100;

  return (
    <div className="home-content">
      <h1>Olá, {user?.name || 'Usuário'}!</h1>
      <p className="subtitle">Falta pouco para você poder iniciar suas mentorias!</p>
      
      <div className="divider"></div>

      <div className="account-completion">
        <h2>Complete sua conta</h2>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>

        <ul className="steps-list">
          {steps.map(step => (
            <li key={step.id} className={step.completed ? 'completed' : ''}>
              <CheckCircleIcon className="step-icon" />
              <span>{step.label}</span>
            </li>
          ))}
        </ul>

        <Link to={`/${user.handle}`} className="profile-button">
          Ir para o meu perfil
        </Link>
      </div>
    </div>
  );
}

export default HomeContent;