import React from 'react';

function Pending() {
  return (
    <div>
      <h1>Inscrição Pendente</h1>
      <p>Recebemos seu cadastro. Fique atento ao seu e-mail para mais informações.</p>
    </div>
  );
}

export default Pending;
