import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Button from '../components/Button/Button';
import EmailInput from '../components/EmailInput/EmailInput';
import Separator from '../components/Separator/Separator';
import './Login.css';

function Login({ setUser, setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/me`, {
          withCredentials: true
        });
        if (response.data) {
          setUser(response.data);
          setIsAuthenticated(true);
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Erro ao verificar autenticação:', error);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const authError = urlParams.get('error');
    if (authError) {
      setError('Falha na autenticação. Por favor, tente novamente.');
    }
  }, [location]);

  const handleGoogleAuth = () => {
    setLoading(true);
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google/login`;
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailContinue = (e) => {
    e.preventDefault();
    
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }
    
    setEmailError(false);
    setLoading(true);
    try {
      // Sua lógica de login existente
    } catch (error) {
      setError('Falha no login. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="login-page">
        <div className="loading">Carregando...</div>
      </div>
    );
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <h1 className="login-title">Acelere sua carreira!</h1>
        <p className="login-subtitle">
          Faça parte da maior comunidade de mentores de saúde de todo o Brasil.
        </p>
        
        <Button 
          variant="google" 
          onClick={handleGoogleAuth} 
          disabled={loading}
        >
          Continuar com Google
        </Button>

        <Separator text="ou" />

        <form onSubmit={handleEmailContinue} noValidate>
          <EmailInput 
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (emailError) setEmailError(false);
            }}
            error={emailError}
          />
          
          <Button 
            type="submit" 
            variant="primary" 
            disabled={loading}
          >
            Continuar com e-mail
          </Button>
        </form>

        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default Login;
