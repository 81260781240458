import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserIcon, XCircleIcon } from '@heroicons/react/24/solid';
import './CompleteProfile.css';

const brazilianStates = [
  { sigla: 'AC', nome: 'Acre' },
  { sigla: 'AL', nome: 'Alagoas' },
  { sigla: 'AP', nome: 'Amapá' },
  { sigla: 'AM', nome: 'Amazonas' },
  { sigla: 'BA', nome: 'Bahia' },
  { sigla: 'CE', nome: 'Ceará' },
  { sigla: 'DF', nome: 'Distrito Federal' },
  { sigla: 'ES', nome: 'Espírito Santo' },
  { sigla: 'GO', nome: 'Goiás' },
  { sigla: 'MA', nome: 'Maranhão' },
  { sigla: 'MT', nome: 'Mato Grosso' },
  { sigla: 'MS', nome: 'Mato Grosso do Sul' },
  { sigla: 'MG', nome: 'Minas Gerais' },
  { sigla: 'PA', nome: 'Pará' },
  { sigla: 'PB', nome: 'Paraíba' },
  { sigla: 'PR', nome: 'Paraná' },
  { sigla: 'PE', nome: 'Pernambuco' },
  { sigla: 'PI', nome: 'Piauí' },
  { sigla: 'RJ', nome: 'Rio de Janeiro' },
  { sigla: 'RN', nome: 'Rio Grande do Norte' },
  { sigla: 'RS', nome: 'Rio Grande do Sul' },
  { sigla: 'RO', nome: 'Rondônia' },
  { sigla: 'RR', nome: 'Roraima' },
  { sigla: 'SC', nome: 'Santa Catarina' },
  { sigla: 'SP', nome: 'São Paulo' },
  { sigla: 'SE', nome: 'Sergipe' },
  { sigla: 'TO', nome: 'Tocantins' }
];

function CompleteProfile() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    profession_id: '',
    is_mentor: false,
    is_mentee: false,
    registration_number: '',
    registration_state: '',
    linkedin_url: '',
    specialties: [] // Vai armazenar objetos {id, name}
  });
  const [professions, setProfessions] = useState([]);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    fetchProfessions();
  }, []);

  useEffect(() => {
    if (formData.profession_id) {
      fetchSpecialties(formData.profession_id);
    }
  }, [formData.profession_id]);

  const fetchProfessions = async () => {
    try {
      console.log('Iniciando busca de profissões');
      const fullUrl = `${process.env.REACT_APP_BACKEND_URL}/api/professions`;
      console.log('URL da requisição:', fullUrl);
      const response = await axios.get(fullUrl, { withCredentials: true });
      console.log('Resposta recebida:', response.data);
      if (Array.isArray(response.data)) {
        setProfessions(response.data);
      } else {
        console.error('Dados de profissões não são um array:', response.data);
        setProfessions([]);
      }
    } catch (error) {
      console.error('Erro ao buscar profissões:', error);
      if (error.response) {
        console.error('Detalhes do erro:', error.response.data);
      }
      setProfessions([]);
    }
  };

  const fetchSpecialties = async (professionId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/professions/${professionId}/specialties`
      );
      setSpecialtyOptions(response.data);
    } catch (error) {
      console.error('Erro ao buscar especialidades:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSpecialtyChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const newSpecialty = { id: parseInt(selectedOption.value), name: selectedOption.text };
    setFormData(prevData => ({
      ...prevData,
      specialties: [...prevData.specialties, newSpecialty]
    }));
  };

  const removeSpecialty = (specialtyId) => {
    setFormData(prevData => ({
      ...prevData,
      specialties: prevData.specialties.filter(s => s.id !== specialtyId)
    }));
  };

  const handleCardClick = (role) => {
    setFormData(prevData => ({
      ...prevData,
      is_mentor: role === 'mentor',
      is_mentee: role === 'mentee'
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        ...formData,
        specialties: formData.specialties.map(s => s.id)
      };
      console.log('Enviando dados:', dataToSend);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/complete-profile`, dataToSend, { withCredentials: true });
      console.log('Resposta do servidor:', response.data);
      if (response.data.message === 'Perfil atualizado com sucesso') {
        setShouldRedirect(true);
      } else {
        throw new Error('Resposta inesperada do servidor');
      }
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      if (error.response) {
        console.error('Detalhes do erro:', error.response.data);
        alert(`Erro ao atualizar perfil: ${error.response.data.error || 'Tente novamente mais tarde.'}`);
      } else {
        alert('Erro ao atualizar perfil. Por favor, tente novamente.');
      }
    }
  };

  const getRegistrationLabel = () => {
    const profession = professions.find(p => p.id === parseInt(formData.profession_id));
    return profession ? (profession.name === 'Médico' ? 'Número do CRM:' : 'Número do COREN:') : 'Número de Registro:';
  };

  console.log('Profissões no render:', professions);

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/pending');
    }
  }, [shouldRedirect, navigate]);

  return (
    <div className="complete-profile-container">
      <div className="complete-profile">
        <h1>Complete seu Perfil</h1>
        <form onSubmit={handleSubmit}>
          <div className="role-selection">
            <div
              className={`role-card ${formData.is_mentee ? 'selected' : ''}`}
              onClick={() => handleCardClick('mentee')}
            >
              <UserIcon className="role-icon" />
              <span>Quero ser mentorado(a)</span>
            </div>
            <div
              className={`role-card ${formData.is_mentor ? 'selected' : ''}`}
              onClick={() => handleCardClick('mentor')}
            >
              <UserIcon className="role-icon" />
              <span>Quero ser um mentor(a)</span>
            </div>
          </div>

          <div className="radio-group">
            <label>Profissão:</label>
            <div className="radio-options">
              {Array.isArray(professions) && professions.length > 0 ? (
                professions.map(profession => (
                  <div key={profession.id} className="radio-option">
                    <input
                      type="radio"
                      id={`profession-${profession.id}`}
                      name="profession_id"
                      value={profession.id}
                      checked={formData.profession_id === profession.id.toString()}
                      onChange={handleChange}
                    />
                    <label htmlFor={`profession-${profession.id}`}>{profession.name}</label>
                  </div>
                ))
              ) : (
                <p>Carregando profissões...</p>
              )}
            </div>
          </div>

          <label>
            {getRegistrationLabel()}
            <input 
              type="text" 
              name="registration_number" 
              value={formData.registration_number} 
              onChange={handleChange} 
            />
          </label>

          <label>
            Estado de Registro:
            <select 
              name="registration_state" 
              value={formData.registration_state} 
              onChange={handleChange}
            >
              <option value="">Selecione um estado</option>
              {brazilianStates.map(state => (
                <option key={state.sigla} value={state.sigla}>
                  {state.nome}
                </option>
              ))}
            </select>
          </label>

          <label>
            LinkedIn URL:
            <input 
              type="text" 
              name="linkedin_url" 
              value={formData.linkedin_url} 
              onChange={handleChange} 
            />
          </label>

          <label>
            Especialidades:
            {specialtyOptions.length > 0 && (
              <select
                value=""
                onChange={handleSpecialtyChange}
                disabled={!formData.profession_id}
              >
                <option value="">Selecione uma especialidade</option>
                {specialtyOptions.map(specialty => (
                  <option key={specialty.id} value={specialty.id}>
                    {specialty.name}
                  </option>
                ))}
              </select>
            )}
          </label>
          <div className="specialty-chips">
            {formData.specialties.map(specialty => (
              <div key={specialty.id} className="specialty-chip">
                {specialty.name}
                <XCircleIcon
                  className="remove-specialty"
                  onClick={() => removeSpecialty(specialty.id)}
                />
              </div>
            ))}
          </div>

          <button type="submit" className="btn-primary">Atualizar Perfil</button>
        </form>
      </div>
    </div>
  );
}

export default CompleteProfile;