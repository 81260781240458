import React from 'react';
import { StarIcon as StarIconSolid } from '@heroicons/react/20/solid';
import { BuildingOffice2Icon, UserIcon, ClockIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import './MentorCard.css';
import { useNavigate } from 'react-router-dom';

function MentorCard({ mentor }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${mentor.handle}`);
  };

  if (!mentor) return null;

  const formatRating = (rating) => {
    if (!rating) return 'Sem avaliações';
    const numRating = parseFloat(rating);
    return isNaN(numRating) ? 'Sem avaliações' : numRating.toFixed(1);
  };

  const calculateYearsOfExperience = (startDate) => {
    if (!startDate) return null;
    const start = new Date(startDate);
    const now = new Date();
    const years = Math.floor((now - start) / (1000 * 60 * 60 * 24 * 365));
    return years > 0 ? years : null;
  };

  const yearsOfExperience = calculateYearsOfExperience(mentor.career_start_date);

  return (
    <div className="mentor-card" onClick={handleClick}>
      <div className="mentor-image-container">
        <img 
          src={mentor.picture || '/default-avatar.png'} 
          alt={mentor.name} 
          className="mentor-image" 
        />
        <div className="mentor-rating">
          <StarIconSolid className="star-icon" />
          <span>{formatRating(mentor.average_rating)}</span>
        </div>
      </div>
      <div className="mentor-info">
        <h2 className="mentor-name">{mentor.name}</h2>
        <p className="mentor-detail">
          <BuildingOffice2Icon className="info-icon" />
          {mentor.company || 'Empresa não informada'}
        </p>
        <p className="mentor-detail">
          <UserIcon className="info-icon" />
          {mentor.profession_name || 'Profissão não informada'}
        </p>
        <p className="mentor-detail">
          <ClockIcon className="info-icon" />
          {yearsOfExperience 
            ? `${yearsOfExperience} anos de carreira`
            : 'Tempo de carreira não informado'}
        </p>
        <p className="mentor-detail">
          <VideoCameraIcon className="info-icon" />
          {mentor.total_mentorships > 0 
            ? `${mentor.total_mentorships} mentorias realizadas`
            : 'Nenhuma mentoria realizada ainda'}
        </p>
        <div className="mentor-tags">
          {Array.isArray(mentor.specialties) && mentor.specialties.length > 0 ? (
            mentor.specialties.map((specialty, index) => (
              <span key={index} className="mentor-tag">
                {specialty}
              </span>
            ))
          ) : (
            <span className="mentor-tag empty-tag">Especialidades não informadas</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default MentorCard;