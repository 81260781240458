import React from 'react';
import PropTypes from 'prop-types';
import './EmailInput.css';

const EmailInput = ({ value, onChange, error }) => {
  return (
    <div className="email-input-container">
      <input
        type="email"
        className={`email-input ${error ? 'invalid' : ''}`}
        value={value}
        onChange={onChange}
        placeholder="Digite seu e-mail"
      />
      {error && (
        <span className="error-message">
          Por favor, insira um e-mail válido
        </span>
      )}
    </div>
  );
};

EmailInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool
};

export default EmailInput; 