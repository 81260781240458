import React from 'react';
import './Separator.css';

const Separator = ({ text }) => {
  return (
    <div className="separator">
      <div className="separator-line"></div>
      <div className="separator-text">{text}</div>
      <div className="separator-line"></div>
    </div>
  );
};

export default Separator; 