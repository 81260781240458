import React from 'react';
import './Filters.css';

function Filters({ filters, activeFilter, setActiveFilter }) {
  return (
    <div className="filters">
      {filters.map(filter => (
        <button 
          key={filter} 
          className={`filter ${activeFilter === filter ? 'active' : ''}`}
          onClick={() => setActiveFilter(filter)}
        >
          {filter}
        </button>
      ))}
    </div>
  );
}

export default Filters;