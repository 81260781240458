import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeIcon, MagnifyingGlassIcon, CalendarIcon, ClockIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import './Sidebar.css';

function Sidebar() {
  const location = useLocation();

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/' || location.pathname === '/dashboard';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <div className="sidebar">
      <nav className="sidebar-nav">
        <Link to="/" className={`nav-item ${isActive('/') ? 'active' : ''}`}>
          <HomeIcon className="nav-icon" />
          <span>Home</span>
        </Link>
        <Link to="/explore" className={`nav-item ${isActive('/explore') ? 'active' : ''}`}>
          <MagnifyingGlassIcon className="nav-icon" />
          <span>Explorar</span>
        </Link>
        <Link to="/agendamentos" className={`nav-item ${isActive('/agendamentos') ? 'active' : ''}`}>
          <CalendarIcon className="nav-icon" />
          <span>Agendamentos</span>
        </Link>
        <Link to="/disponibilidade" className={`nav-item ${isActive('/disponibilidade') ? 'active' : ''}`}>
          <ClockIcon className="nav-icon" />
          <span>Disponibilidade</span>
        </Link>
        <Link to="/monetizacao" className={`nav-item ${isActive('/monetizacao') ? 'active' : ''}`}>
          <CurrencyDollarIcon className="nav-icon" />
          <span>Monetização</span>
          <span className="badge">EM BREVE</span>
        </Link>
      </nav>
    </div>
  );
}

export default Sidebar;