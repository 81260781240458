import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { XCircleIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import './SpecialtySelect.css';

const SpecialtySelect = ({ 
  selectedSpecialties, 
  onChange, 
  onRemove, 
  professionId 
}) => {
  const [specialties, setSpecialties] = useState([]);

  useEffect(() => {
    const fetchSpecialties = async () => {
      if (professionId) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/professions/${professionId}/specialties`
          );
          setSpecialties(response.data);
        } catch (error) {
          console.error('Erro ao carregar especialidades:', error);
        }
      }
    };

    fetchSpecialties();
  }, [professionId]);

  const handleChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const newSpecialty = {
      id: parseInt(selectedOption.value),
      name: selectedOption.text
    };
    onChange(newSpecialty);
    e.target.value = '';
  };

  return (
    <div className="specialty-select">
      <select value="" onChange={handleChange} className="form-select">
        <option value="">Selecione uma especialidade</option>
        {specialties.map(specialty => (
          <option 
            key={`specialty-option-${specialty.id}`} 
            value={specialty.id}
          >
            {specialty.name}
          </option>
        ))}
      </select>

      <div className="specialty-chips">
        {selectedSpecialties.map(specialty => (
          <div 
            key={`specialty-chip-${specialty.id}`} 
            className="specialty-chip"
          >
            {specialty.name}
            <XCircleIcon
              className="remove-specialty"
              onClick={() => onRemove(specialty.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

SpecialtySelect.propTypes = {
  selectedSpecialties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  professionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};

SpecialtySelect.defaultProps = {
  selectedSpecialties: []
};

export default SpecialtySelect; 