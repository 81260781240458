import React from 'react';
import './Tabs.css';

function Tabs({ tabs, activeTab, setActiveTab }) {
  return (
    <div className="tabs">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`tab ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label}
          {tab.badge > 0 && (
            <span className="tab-badge">
              {tab.badge}
            </span>
          )}
        </button>
      ))}
    </div>
  );
}

export default Tabs;