import React, { useState, useEffect } from 'react';
import InviteCard from '../components/InviteCard';
import Tabs from '../components/Tabs';
import { sessionApi } from '../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Agendamentos.css';

function Agendamentos() {
  const [activeTab, setActiveTab] = useState('confirmadas');
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSessions();
  }, [activeTab]);

  const fetchSessions = async () => {
    try {
      setLoading(true);
      const response = await sessionApi.getMentorSessions();
      setSessions(response.data);
    } catch (error) {
      setError('Erro ao carregar agendamentos');
      console.error('Erro:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async (sessionId) => {
    try {
      setLoading(true);
      const response = await sessionApi.confirmSession(sessionId);

      if (response.data.meetLink) {
        console.log('Link do Meet gerado:', response.data.meetLink);
      }

      await fetchSessions();
      toast.success('Mentoria confirmada com sucesso!');
    } catch (error) {
      console.error('Erro ao confirmar sessão:', error);
      toast.error('Erro ao confirmar mentoria. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async (sessionId) => {
    try {
      await sessionApi.cancelSession(sessionId);
      await fetchSessions();
      toast.success('Mentoria recusada com sucesso');
    } catch (error) {
      console.error('Erro ao recusar sessão:', error);
      toast.error('Erro ao recusar mentoria');
    }
  };

  const filterSessions = () => {
    return sessions.filter(session => {
      switch (activeTab) {
        case 'confirmadas':
          return session.status === 'confirmada';
        case 'aguardando':
          return session.status === 'pendente';
        case 'historico':
          return session.status === 'concluída';
        default:
          return false;
      }
    });
  };

  const pendingCount = sessions.filter(s => s.status === 'pendente').length;

  const tabs = [
    { id: 'confirmadas', label: 'Confirmadas' },
    { 
      id: 'aguardando', 
      label: 'Aguardando resposta',
      badge: pendingCount || undefined
    },
    { id: 'historico', label: 'Histórico' }
  ];

  return (
    <div className="agendamentos-page">
      <div className="page-header">
        <h1>Agendamentos</h1>
        <p className="subtitle">
          Aqui você visualiza suas mentorias agendadas e também o seu histórico.
        </p>
      </div>

      <Tabs 
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <div className="sessions-list">
        {loading ? (
          <div className="loading">Carregando...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : filterSessions().length === 0 ? (
          <div className="empty-state">
            Nenhuma mentoria {activeTab === 'aguardando' ? 'pendente' : activeTab} encontrada
          </div>
        ) : (
          filterSessions().map(session => (
            <InviteCard
              key={session.id}
              session={session}
              onConfirm={handleConfirm}
              onDecline={handleDecline}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Agendamentos; 