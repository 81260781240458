import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import './Availability.css';
import axios from 'axios'; // Certifique-se de ter instalado o axios: npm install axios
import { useNavigate } from 'react-router-dom'; // Adicione este hook no topo
import { userApi, availabilityApi } from '../services/api';

// Função para gerar opções de horário de meia em meia hora, começando às 7h e indo até 23:59
const generateTimeOptions = () => {
  const options = [];
  for (let i = 7; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const hour = i.toString().padStart(2, '0');
      const minute = j.toString().padStart(2, '0');
      options.push(`${hour}:${minute}`);
    }
  }
  options.push('23:59');
  return options;
};

// Função para calcular a duração entre dois horários
const calculateDuration = (start, end) => {
  if (!start || !end) return '';
  const [startHour, startMinute] = start.split(':').map(Number);
  const [endHour, endMinute] = end.split(':').map(Number);
  let durationHours = endHour - startHour;
  let durationMinutes = endMinute - startMinute;
  
  if (durationMinutes < 0) {
    durationHours--;
    durationMinutes += 60;
  }
  
  if (durationHours === 0 && durationMinutes === 0) return '';
  
  const hours = durationHours > 0 ? `${durationHours} hora${durationHours > 1 ? 's' : ''}` : '';
  const minutes = durationMinutes > 0 ? `${durationMinutes} minuto${durationMinutes > 1 ? 's' : ''}` : '';
  
  return `(${hours}${hours && minutes ? ' e ' : ''}${minutes})`;
};

// Componente para o ícone de fechar
const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ED6767" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

function Availability() {
  const [mentorId, setMentorId] = useState(null);
  const navigate = useNavigate();
  const [availabilities, setAvailabilities] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [tempAvailabilities, setTempAvailabilities] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isCalendarConnected, setIsCalendarConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setIsLoading(true);
        const response = await userApi.getCurrentUser();
        
        if (response.data && response.data.id) {
          if (!response.data.is_mentor) {
            navigate('/dashboard');
            return;
          }
          setMentorId(response.data.id);
          await loadAvailabilities(response.data.id);
        }
      } catch (error) {
        console.error('Erro ao verificar autenticação:', error);
        if (error.response?.status === 401) {
          navigate('/login', { state: { from: '/availability' } });
        } else {
          navigate('/dashboard');
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [navigate]);

  const loadAvailabilities = async (id) => {
    try {
      const response = await availabilityApi.getMentorAvailability(id);
      if (response.data) {
        setAvailabilities(response.data);
        if (!isEditing) {
          setTempAvailabilities(response.data);
        }
      }
    } catch (error) {
      console.error('Erro ao carregar disponibilidades:', error);
      // Inicializar com estrutura padrão se houver erro
      const defaultAvailabilities = {
        domingo: { enabled: false, slots: [] },
        segunda: { enabled: false, slots: [] },
        terca: { enabled: false, slots: [] },
        quarta: { enabled: false, slots: [] },
        quinta: { enabled: false, slots: [] },
        sexta: { enabled: false, slots: [] },
        sabado: { enabled: false, slots: [] }
      };
      setAvailabilities(defaultAvailabilities);
      setTempAvailabilities(defaultAvailabilities);
    }
  };

  const saveChanges = async () => {
    if (!mentorId) return;
    
    setIsSaving(true);
    setSaveError(null);
    try {
      const response = await availabilityApi.updateAvailability(mentorId, tempAvailabilities);
      await loadAvailabilities(mentorId);
      setIsEditing(false);
      
      // Atualizar o estado do usuário no Dashboard
      const userResponse = await userApi.getCurrentUser();
      if (userResponse.data) {
        // Se você estiver usando algum gerenciador de estado global (Context, Redux, etc)
        // atualize o estado do usuário aqui
      }
    } catch (error) {
      console.error('Erro ao salvar disponibilidades:', error);
      setSaveError('Ocorreu um erro ao salvar as disponibilidades. Por favor, tente novamente.');
    } finally {
      setIsSaving(false);
    }
  };

  const startEditing = () => {
    setIsEditing(true);
    setTempAvailabilities(availabilities);
  };

  const cancelChanges = () => {
    setIsEditing(false);
    setTempAvailabilities({});
  };

  const handleConnectCalendar = async () => {
    try {
      setIsConnecting(true);
      // Redireciona para a rota de autenticação do Google com os escopos necessários
      window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google/calendar`;
    } catch (error) {
      console.error('Erro ao conectar calendário:', error);
    }
  };

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  const timeOptions = generateTimeOptions();

  const saveAvailabilities = async () => {
    setIsSaving(true);
    setSaveError(null);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/availabilities/${mentorId}`, availabilities);
      console.log('Disponibilidades salvas com sucesso');
    } catch (error) {
      console.error('Erro ao salvar disponibilidades:', error);
      setSaveError('Ocorreu um erro ao salvar as disponibilidades. Por favor, tente novamente.');
    } finally {
      setIsSaving(false);
    }
  };

  const toggleDay = (day) => {
    setTempAvailabilities(prev => {
      const updatedDay = { ...prev[day], enabled: !prev[day].enabled };
      if (updatedDay.enabled && updatedDay.slots.length === 0) {
        updatedDay.slots = [{ start: '', end: '' }];
      }
      return { ...prev, [day]: updatedDay };
    });
  };

  const addTimeSlot = (day) => {
    setTempAvailabilities(prev => ({
      ...prev,
      [day]: {
        ...prev[day],
        slots: [...prev[day].slots, { start: '', end: '' }]
      }
    }));
  };

  const removeTimeSlot = (day, index) => {
    setTempAvailabilities(prev => {
      const updatedSlots = prev[day].slots.filter((_, i) => i !== index);
      
      // Se era o último slot, desativa o toggle do dia
      if (updatedSlots.length === 0) {
        return {
          ...prev,
          [day]: {
            ...prev[day],
            enabled: false,
            slots: []
          }
        };
      }
      
      // Caso contrário, apenas remove o slot
      return {
        ...prev,
        [day]: {
          ...prev[day],
          slots: updatedSlots
        }
      };
    });
  };

  // Função para obter todos os horários disponíveis
  const getAvailableTimes = (day, currentSlotIndex) => {
    const occupiedRanges = availabilities[day].slots
      .filter((_, index) => index !== currentSlotIndex)
      .map(slot => [timeOptions.indexOf(slot.start), timeOptions.indexOf(slot.end)]);

    occupiedRanges.sort((a, b) => a[0] - b[0]);

    const availableTimes = [];
    let lastEnd = 0;

    for (const [start, end] of occupiedRanges) {
      if (start > lastEnd) {
        availableTimes.push(...timeOptions.slice(lastEnd, start));
      }
      lastEnd = Math.max(lastEnd, end);
    }

    if (lastEnd < timeOptions.length) {
      availableTimes.push(...timeOptions.slice(lastEnd));
    }

    return availableTimes;
  };

  // Função para filtrar as opções de horário inicial
  const getStartTimeOptions = (day, currentSlotIndex) => {
    return getAvailableTimes(day, currentSlotIndex);
  };

  // Função para filtrar as opções de horário final
  const getEndTimeOptions = (startTime, day, currentSlotIndex) => {
    if (!startTime) return [];
    const availableTimes = getAvailableTimes(day, currentSlotIndex);
    const startIndex = timeOptions.indexOf(startTime);
    const nextSlotStart = availabilities[day].slots
      .filter((_, index) => index !== currentSlotIndex)
      .sort((a, b) => timeOptions.indexOf(a.start) - timeOptions.indexOf(b.start))
      .find(slot => timeOptions.indexOf(slot.start) > startIndex)?.start;

    return availableTimes.filter(time => {
      const timeIndex = timeOptions.indexOf(time);
      return timeIndex > startIndex && (!nextSlotStart || timeIndex <= timeOptions.indexOf(nextSlotStart));
    });
  };

  // Função atualizada para adicionar ou atualizar um slot
  const updateTimeSlot = (day, slotIndex, field, value) => {
    setTempAvailabilities(prev => {
      const updatedSlots = [...prev[day].slots];
      updatedSlots[slotIndex] = { ...updatedSlots[slotIndex], [field]: value };
      
      // Se estamos atualizando o horário final, garantimos que ele seja após o horário inicial
      if (field === 'end' && updatedSlots[slotIndex].start && value <= updatedSlots[slotIndex].start) {
        return prev; // Não atualizamos se o horário final não for válido
      }
      
      return {
        ...prev,
        [day]: {
          ...prev[day],
          slots: updatedSlots
        }
      };
    });
  };

  return (
    <div className="availability-page">
      <h1>Disponibilidade</h1>
      
      <div className="calendar-connect">
        <img src="/calendar_icon.png" alt="Calendar" className="calendar-icon" />
        <div className="calendar-text">
          <h2>Conecte seu calendário</h2>
          <p>Sincronize seu calendário para gerenciar suas disponibilidades de forma mais eficiente.</p>
        </div>
        <button 
          className={`connect-button ${isConnecting ? 'connecting' : ''} ${isCalendarConnected ? 'connected' : ''}`}
          onClick={handleConnectCalendar}
          disabled={isConnecting || isCalendarConnected}
        >
          {isCalendarConnected 
            ? 'Calendário Conectado' 
            : isConnecting 
              ? 'Conectando...' 
              : 'Conectar Calendário'
          }
        </button>
      </div>

      <h2>Personalize sua disponibilidade</h2>
      <div className="subtitle-container">
        <p className="subtitle">
          Escolha os dias e horários que você estará disponível para fazer mentorias.
          Você pode adicionar mais de um bloco de horários por dia.
        </p>
        {!isEditing && (
          <button className="edit-button" onClick={startEditing}>
            Editar
          </button>
        )}
      </div>

      {isLoading ? (
        <p>Carregando disponibilidades...</p>
      ) : (
        <div className="availability-list">
          {Object.entries(isEditing ? tempAvailabilities : availabilities).map(([day, { enabled, slots }]) => (
            <div key={day} className="day-row">
              <div className="day-info">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={enabled}
                    onChange={() => toggleDay(day)}
                    disabled={!isEditing}
                  />
                  <span className="slider"></span>
                </label>
                <span className="day-name">{day.charAt(0).toUpperCase() + day.slice(1)}</span>
              </div>
              <div className="time-slots-container">
                {enabled && slots.map((slot, index) => (
                  <div key={index} className="time-slots-and-add">
                    <div className="time-slot">
                      <select
                        value={slot.start}
                        onChange={(e) => updateTimeSlot(day, index, 'start', e.target.value)}
                        disabled={!isEditing}
                      >
                        <option value="">Início</option>
                        {timeOptions.map(time => (
                          <option key={time} value={time}>{time}</option>
                        ))}
                      </select>
                      <span>até</span>
                      <select
                        value={slot.end}
                        onChange={(e) => updateTimeSlot(day, index, 'end', e.target.value)}
                        disabled={!isEditing}
                      >
                        <option value="">Fim</option>
                        {timeOptions.map(time => (
                          <option key={time} value={time}>{time}</option>
                        ))}
                      </select>
                      {isEditing && (
                        <button onClick={() => removeTimeSlot(day, index)} className="remove-slot">
                          <CloseIcon />
                          Remover
                        </button>
                      )}
                    </div>
                    {isEditing && index === slots.length - 1 && (
                      <button onClick={() => addTimeSlot(day)} className="add-slot-button">
                        Adicionar novo bloco de horário
                      </button>
                    )}
                  </div>
                ))}
                {isEditing && enabled && slots.length === 0 && (
                  <div className="time-slots-and-add">
                    <button onClick={() => addTimeSlot(day)} className="add-slot-button">
                      Adicionar novo bloco de horário
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {isEditing && (
        <div className="edit-actions">
          <button onClick={cancelChanges} className="cancel-button">Cancelar</button>
          <button 
            onClick={saveChanges} 
            className="save-button" 
            disabled={isSaving}
          >
            {isSaving ? 'Salvando...' : 'Salvar alterações'}
          </button>
        </div>
      )}
      {saveError && <p className="error-message">{saveError}</p>}
    </div>
  );
}

export default Availability;
