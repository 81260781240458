import React from 'react';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Button from '../Button/Button';
import './Modal.css';

const Modal = ({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  footer,
  size = 'medium' // small, medium, large
}) => {
  if (!isOpen) return null;

  const defaultFooter = (
    <>
      <Button variant="secondary" onClick={onClose}>
        Cancelar
      </Button>
      <Button variant="primary" onClick={onClose}>
        Confirmar
      </Button>
    </>
  );

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div 
          className={`modal-container modal-${size}`} 
          onClick={e => e.stopPropagation()}
        >
          <div className="modal-header">
            <h2 className="modal-title">{title}</h2>
            <button className="modal-close" onClick={onClose}>
              <XMarkIcon className="close-icon" />
            </button>
          </div>

          <div className="modal-content">
            {children}
          </div>

          {(footer || defaultFooter) && (
            <div className="modal-footer">
              {footer || defaultFooter}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default Modal; 